'use strict';
var config = function($window,$log) {
  this.$window=$window;
  this.$log=$log;
  var defaultPredateSelectors={
    'SINGL':{'id':'SINGL','labelen':'1 day','labelnl':'1 dag','labelfr':'1 jour','minEnddateOffset':'0','maxEnddateOffset':'0','order':'0','hraPerHotel':{},'selectFirstEnddate':false,'local':true,'default':true},
    'MULTI':{'id':'MULTI','labelen':'2 days or more','labelnl':'2 dagen of meer','labelfr':'2 jours ou plus','minEnddateOffset':'1','maxEnddateOffset':'31','order':'1','hraPerHotel':{},'selectFirstEnddate':false,'local':true,'default':false}
  };
  this.hosts={
    'localhost': {
      // 'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-5WQGZ2T',
      'mode': 'site',
      'ffo':'PD',
      'title':'Booking | Durbuy Green Fields',
      'favicon':'https://assets-global.website-files.com/65a540a98a6d966688fd0407/65dc733fdfa5598c371f391e_favicon.png',
      'formbutton': '',
      'url': 'https://padel.durbuygreenfields.com/',
      'urlhash': 'e69ff54cab7cfd680dfaaa272cf4e8831e5d3f4b702311a7822b53a5cda3b217',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://en.durbuygreenfields.com/mentions-legales',
            'nl': 'https://nl.durbuygreenfields.com/mentions-legales',
            'fr': 'https://www.durbuygreenfields.com/mentions-legales'
          }
        }
      ],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://en.durbuygreenfields.com/cookies',
          'nl': 'https://nl.durbuygreenfields.com/cookies',
          'fr': 'https://www.durbuygreenfields.com/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://en.durbuygreenfields.com/confirmation-de-votre-reservation',
        'nl': 'https://nl.durbuygreenfields.com/confirmation-de-votre-reservation',
        'fr': 'https://www.durbuygreenfields.com/confirmation-de-votre-reservation'
      },
      'logo': {
        'alt':'Logo Durbuy Green Fields',
        'src':'/assets/images/logo-greenfields.svg',
        'href':{
          'en':'https://en.durbuygreenfields.com/',
          'nl':'https://nl.durbuygreenfields.com/',
          'fr':'https://www.durbuygreenfields.com/?no_redirect=true'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'outdoor.lapetitemerveille.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':false,
      'ctepScript':'ctepForKiosk',
      'defaultLang':'fr',
      'gmtId':'GTM-K4HJMLQ',
      'mode':'kiosk',
      'ffo':'AV',
      'formbutton': '',
      'url': 'https://outdoor.lapetitemerveille.be/kiosk/',
      'urlhash': 'e73b62e92e45f1bdae2c1ca7b543befc4b3bad0fa54f64ca2e54df752359b2a1',
      'promoCode': '',
      'termsAndConditionsLinks':[],
      'privacyLink':null,
      'proposeVoucherPayment':false,
      'finishedRedirect': {},
      'logo': {
        'alt':'Logo Adventure Valley',
        'src':'/assets/images/logo-adventure-valley.svg',
        'href':{
          'en': 'https://www.adventure-valley.be/en',
          'nl': 'https://www.adventure-valley.be/nl',
          'fr': 'https://www.adventure-valley.be'
        }
      },
      'onlineDiscountMessage':{}
    },
    'booking.adventure-valley.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-K4HJMLQ',
      'title':'Booking | Adventure-valley',
      'favicon':'https://www.adventure-valley.be/sites/advv.be/themes/custom/advv_theme/favicon.ico',
      'mode': 'site',
      'ffo':'AV',
      'formbutton': '',
      'url': 'https://booking.adventure-valley.be/',
      'urlhash': '7bd9655b54f1c1e1f5ccb0ee15efb12f9a1ec0700ceb8a7d719db039de623569',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://www.adventure-valley.be/en/park-rules',
          'nl': 'https://www.adventure-valley.be/nl/verkoop-en-parkreglement',
          'fr': 'https://www.adventure-valley.be/reglementation-des-ventes-et-des-parcs'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://www.adventure-valley.be/en/cookies',
          'nl': 'https://www.adventure-valley.be/nl/cookies',
          'fr': 'https://www.adventure-valley.be/cookies'
        }
      },
      'finishedRedirect': {
        'en':'https://www.adventure-valley.be/en/activityconfirmation',
        'nl':'https://www.adventure-valley.be/nl/activiteitbevestiging',
        'fr':'https://www.adventure-valley.be/activiteconfirmation'
      },
      'proposeVoucherPayment':true,
      'logo': {
        'alt':'Logo Adventure Valley',
        'src':'/assets/images/logo-adventure-valley.svg',
        'href':{
          'en': 'https://www.adventure-valley.be/en',
          'nl': 'https://www.adventure-valley.be/nl',
          'fr': 'https://www.adventure-valley.be'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors': {'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.adventure-valley.be/giftcard/': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-K4HJMLQ',
      'title':'Booking | Adventure-valley',
      'favicon':'https://www.adventure-valley.be/sites/advv.be/themes/custom/advv_theme/favicon.ico',
      'mode': 'site',
      'ffo':'GA',
      'formbutton': '',
      'url': 'https://booking.adventure-valley.be/giftcard/',
      'urlhash': 'f2b6dbd60000fc15253f69df22d54bfe1c762baaaebdac4297b6c4930f97cfd1',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://www.adventure-valley.be/en/park-rules',
          'nl': 'https://www.adventure-valley.be/nl/verkoop-en-parkreglement',
          'fr': 'https://www.adventure-valley.be/reglementation-des-ventes-et-des-parcs'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://www.adventure-valley.be/en/cookies',
          'nl': 'https://www.adventure-valley.be/nl/cookies',
          'fr': 'https://www.adventure-valley.be/cookies'
        }
      },
      'finishedRedirect': {
        'en':'https://www.adventure-valley.be/en/activityconfirmation',
        'nl':'https://www.adventure-valley.be/nl/activiteitbevestiging',
        'fr':'https://www.adventure-valley.be/activiteconfirmation'
      },
      'proposeVoucherPayment':true,
      'skipDatesPage':true,
      'logo': {
        'alt':'Logo Adventure Valley',
        'src':'/assets/images/logo-adventure-valley.svg',
        'href':{
          'en': 'https://www.adventure-valley.be/en',
          'nl': 'https://www.adventure-valley.be/nl',
          'fr': 'https://www.adventure-valley.be'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.adventure-valley.be/company-giftcard/': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-K4HJMLQ',
      'title':'Booking | Adventure-valley',
      'favicon':'https://www.adventure-valley.be/sites/advv.be/themes/custom/advv_theme/favicon.ico',
      'mode': 'site',
      'ffo':'GB',
      'formbutton': '',
      'url': 'https://booking.adventure-valley.be/company-giftcard/',
      'urlhash': '59ddf0e356e68e933dce0fb1fb92c5c6cce34ccdd6f70fb7ebb8efd305502611',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://www.adventure-valley.be/en/park-rules',
          'nl': 'https://www.adventure-valley.be/nl/verkoop-en-parkreglement',
          'fr': 'https://www.adventure-valley.be/reglementation-des-ventes-et-des-parcs'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://www.adventure-valley.be/en/cookies',
          'nl': 'https://www.adventure-valley.be/nl/cookies',
          'fr': 'https://www.adventure-valley.be/cookies'
        }
      },
      'finishedRedirect': {
        'en':'https://www.adventure-valley.be/en/activityconfirmation',
        'nl':'https://www.adventure-valley.be/nl/activiteitbevestiging',
        'fr':'https://www.adventure-valley.be/activiteconfirmation'
      },
      'proposeVoucherPayment':true,
      'skipDatesPage':true,
      'logo': {
        'alt':'Logo Adventure Valley',
        'src':'/assets/images/logo-adventure-valley.svg',
        'href':{
          'en': 'https://www.adventure-valley.be/en',
          'nl': 'https://www.adventure-valley.be/nl',
          'fr': 'https://www.adventure-valley.be'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking-halloween.adventure-valley.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-K4HJMLQ',
      'title':'Booking | Adventure-valley',
      'favicon':'https://www.adventure-valley.be/sites/advv.be/themes/custom/advv_theme/favicon.ico',
      'mode': 'site',
      'ffo':'MV',
      'formbutton': '',
      'url': 'https://booking-halloween.adventure-valley.be/',
      'urlhash': '57a12268bccd946b2b0a0b148292a3955fff727bcae6073c16299bdbf739a5da',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en':'https://www.adventure-valley.be/en/disclaimer',
          'nl':'https://www.adventure-valley.be/nl/juridische-vermeldingen',
          'fr':'https://www.adventure-valley.be/mentions-legales'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en':'https://www.adventure-valley.be/en/cookies',
          'nl':'https://www.adventure-valley.be/nl/cookies',
          'fr':'https://www.adventure-valley.be/cookies'
        }
      },
      'proposeVoucherPayment':false,
      'finishedRedirect': {
        'en':'https://halloween.adventure-valley.be/confirmation-reservation',
        'nl':'https://nl.halloween.adventure-valley.be/boekingsbevestiging',
        'fr':'https://halloween.adventure-valley.be/confirmation-reservation'
      },
      'logo': {
        'alt':'Logo Halloween Adventure Valley',
        'src':'https://uploads-ssl.webflow.com/612e493f669f4894c189b017/61445a15be88988461b4d88a_logo%2Bdates.png',
        'href':{'en':'https://halloween.adventure-valley.be/','nl':'https://nl.halloween.adventure-valley.be/','fr':'https://halloween.adventure-valley.be/'}
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'stages.adventure-valley.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-K4HJMLQ',
      'title':'Booking | Adventure-valley',
      'favicon':'https://www.adventure-valley.be/sites/advv.be/themes/custom/advv_theme/favicon.ico',
      'mode': 'site',
      'ffo':'SA',
      'formbutton':'',
      'url': 'https://stages.adventure-valley.be/',
      'urlhash': '301884fe86bed1aad8ea6f5b85ccf9cefb8f55291677f011ba92ebcd30f3e438',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://www.adventure-valley.be/en/general-terms',
            'nl': 'https://www.adventure-valley.be/nl/algemene-huurvoorwaarden',
            'fr': 'https://www.adventure-valley.be/conditions-generales-location'
          }
        }
      ],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://www.adventure-valley.be/en/cookies',
          'nl': 'https://www.adventure-valley.be/nl/cookies',
          'fr': 'https://www.adventure-valley.be/cookies'
        }
      },
      'finishedRedirect': {
        'en': 'https://www.adventure-valley.be/en/thank-you-your-payment',
        'nl': 'https://www.adventure-valley.be/nl/inschrijving-voor-de-dagkampen',
        'fr': 'https://www.adventure-valley.be/merci-pour-votre-paiement'
      },
      'proposeVoucherPayment': true,
      'logo': {
        'alt':'Logo Adventure Valley',
        'src':'/assets/images/logo-adventure-valley.svg',
        'href':{
          'en': 'https://www.adventure-valley.be/en/',
          'nl': 'https://www.adventure-valley.be/nl/',
          'fr': 'https://www.adventure-valley.be/'
        }
      },
      'onlineDiscountMessage': {
        'en': 'reduction on activities when paid online',
        'nl': 'korting op activiteiten indien u online betaalt',
        'fr': 'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'
      },
      'predateSelectors': {}
    },
    'ardennes-etape.lapetitemerveille.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-M4WSH68',
      'title':'Booking | Ardennes Étape',
      'favicon':'https://fr.ardennes-etape.be/images/favIcon/favicon.ico',
      'mode': 'site',
      'ffo':'AE',
      'formbutton':'',
      'url': 'https://ardennes-etape.lapetitemerveille.be/',
      'urlhash': 'c8e07f70ca5a57bae2fc2f866b511a843d235c2cc7908be8e18f39ca13ac4385',
      'promoCode': '',
      'termsAndConditionsLinks':[],
      'privacyLink':null,
      'proposeVoucherPayment':false,
      'finishedRedirect': {
        'en':'https://ardennes-etape.lapetitemerveille.be/confirmation/en/',
        'nl':'https://ardennes-etape.lapetitemerveille.be/confirmation/nl/',
        'fr':'https://ardennes-etape.lapetitemerveille.be/confirmation/'
      },
      'logo': {
        'alt':'Logo Ardennes Etape',
        'src':'/assets/images/logo-ardennes-etape.svg',
        'href':{
          'en':'https://en.ardennes-etape.be/',
          'nl':'https://www.ardennes-etape.be/',
          'fr':'https://fr.ardennes-etape.be/'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'partner':'AET'
    },
    'cera.lapetitemerveille.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-M4WSH68',
      'title':'Booking | CERA',
      'favicon':'https://cera-cdn.azureedge.net/-/media/cera/ceraweb/favicon.ashx',
      'mode': 'site',
      'ffo':'CE',
      'formbutton': '',
      'url': 'https://cera.lapetitemerveille.be/',
      'urlhash': '5e634c2c82f4757f6d70208f459101ff1c5bf269358cfeccb90521e742d92f53',
      'promoCode': '',
      'termsAndConditionsLinks':[],
      'privacyLink':null,
      'proposeVoucherPayment':false,
      'undefinedRefererRedirectUrl': {
        'en' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'fr' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'nl' : 'https://www.cera.coop/nl/particulieren/genieten-van-voordelen/voordelen-zoeken/verwennerij-in-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf'
      },
      'finishedRedirect': {
        'en':'https://cera.lapetitemerveille.be/confirmation/en/',
        'nl':'https://cera.lapetitemerveille.be/confirmation/nl/',
        'fr':'https://cera.lapetitemerveille.be/confirmation/'
      },
      'logo': {
        'alt':'Logo CERA',
        'src':'/assets/images/logo-cera.svg',
        'href':{
          'en':'https://www.cera.coop/fr/particuliers',
          'nl':'https://www.cera.coop/fr/particuliers',
          'fr':'https://www.cera.coop/fr/particuliers'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'partner':'CER'
    },
    'booking.durbuyproperties.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-TVBF979',
      'title':'Booking | Durbuy Properties',
      'favicon':'https://www.durbuyproperties.be/favicon-16x16.png',
      'mode': 'site',
      'ffo': 'SE',
      'formbutton': '',
      'url': 'https://booking.durbuyproperties.be/',
      'urlhash': 'bffa6441c411166825191ee0642c7bb9ab59898dfadc8202855ab5049940d4dd',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://www.durbuyproperties.be/mentions-legales',
            'nl': 'https://www.durbuyproperties.be/nl/juridische-vermeldingen',
            'fr': 'https://www.durbuyproperties.be/mentions-legales'
          }
        }
      ],
      'proposeVoucherPayment': true,
      'finishedRedirect': {
        'en': 'https://www.durbuyproperties.be/confirmation-reservation',
        'nl': 'https://www.durbuyproperties.be/nl/boekingsbevestiging',
        'fr': 'https://www.durbuyproperties.be/confirmation-reservation'
      },
      'logo': {
        'alt':'Logo Durbuy Properties',
        'src':'/assets/images/logo-durbuy-properties.svg',
        'href':{
          'en':'https://www.durbuyproperties.be/',
          'nl':'https://www.durbuyproperties.be/nl/',
          'fr':'https://www.durbuyproperties.be/'
        }
      },
      'onlineDiscountMessage': {
        'en': 'reduction on activities when paid online',
        'nl': 'korting op activiteiten indien u online betaalt',
        'fr': 'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'
      },
      'predateSelectors':{}
    },
    'booking.durbuygreenfields.com': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-5WQGZ2T',
      'mode': 'site',
      'ffo':'TM',
      'title':'Booking | Durbuy Green Fields',
      'favicon':'https://assets-global.website-files.com/65a540a98a6d966688fd0407/65dc733fdfa5598c371f391e_favicon.png',
      'formbutton': '',
      'url': 'https://booking.durbuygreenfields.com/',
      'urlhash': 'e69ff54cab7cfd680dfaaa272cf4e8831e5d3f4b702311a7822b53a5cda3b217',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://en.durbuygreenfields.com/mentions-legales',
            'nl': 'https://nl.durbuygreenfields.com/mentions-legales',
            'fr': 'https://www.durbuygreenfields.com/mentions-legales'
          }
        }
      ],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://en.durbuygreenfields.com/cookies',
          'nl': 'https://nl.durbuygreenfields.com/cookies',
          'fr': 'https://www.durbuygreenfields.com/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://en.durbuygreenfields.com/confirmation-de-votre-reservation',
        'nl': 'https://nl.durbuygreenfields.com/confirmation-de-votre-reservation',
        'fr': 'https://www.durbuygreenfields.com/confirmation-de-votre-reservation'
      },
      'logo': {
        'alt':'Logo Durbuy Green Fields',
        'src':'/assets/images/logo-greenfields.svg',
        'href':{
          'en':'https://en.durbuygreenfields.com/',
          'nl':'https://nl.durbuygreenfields.com/',
          'fr':'https://www.durbuygreenfields.com/?no_redirect=true'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'MULTI':defaultPredateSelectors.MULTI}
    },
    'padel.durbuygreenfields.com': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-5WQGZ2T',
      'mode': 'site',
      'ffo':'PD',
      'title':'Padel | Durbuy Green Fields',
      'favicon':'https://assets-global.website-files.com/65a540a98a6d966688fd0407/65dc733fdfa5598c371f391e_favicon.png',
      'formbutton': '',
      'url': 'https://padel.durbuygreenfields.com/',
      'urlhash': '96c8a13b9fa4e7db9a461f4b655c38050086c0b5f3df8bd02b86a420fb17936b',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://en.durbuygreenfields.com/mentions-legales',
            'nl': 'https://nl.durbuygreenfields.com/mentions-legales',
            'fr': 'https://www.durbuygreenfields.com/mentions-legales'
          }
        }
      ],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://en.durbuygreenfields.com/cookies',
          'nl': 'https://nl.durbuygreenfields.com/cookies',
          'fr': 'https://www.durbuygreenfields.com/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://en.durbuygreenfields.com/confirmation-de-votre-reservation',
        'nl': 'https://nl.durbuygreenfields.com/confirmation-de-votre-reservation',
        'fr': 'https://www.durbuygreenfields.com/confirmation-de-votre-reservation'
      },
      'logo': {
        'alt':'Logo Durbuy Green Fields',
        'src':'/assets/images/logo-greenfields.svg',
        'href':{
          'en':'https://en.durbuygreenfields.com/',
          'nl':'https://nl.durbuygreenfields.com/',
          'fr':'https://www.durbuygreenfields.com/?no_redirect=true'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.duveldome.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-W88SHPV',
      'title':'Booking | Durbuy Properties',
      'favicon':'/assets/images/favicon/duveldome/favicon-16x16.png',
      'mode': 'site',
      'ffo':'DH',
      'formbutton': '',
      'url': 'https://booking.duveldome.be/',
      'urlhash': '23877c1b57642a2fcd37fe21f80f68d2328ddbbcfd62ea1eed78b0b026a6d928',
      'promoCode': '',
      'termsAndConditionsLinks': [
        {
          'label': {
            'en': 'the terms and conditions',
            'nl': 'de algemene voorwaarden',
            'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
          },
          'href': {
            'en': 'https://www.duveldome.be/en/disclaimer',
            'nl': 'https://www.duveldome.be/nl/juridische-vermeldingen',
            'fr': 'https://www.duveldome.be/mentions-legales'
          }
        }
      ],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://www.duveldome.be/en/cookies',
          'nl': 'https://www.duveldome.be/nl/cookies',
          'fr': 'https://www.duveldome.be/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://www.duveldome.be/en/booking-confirmation',
        'nl': 'https://www.duveldome.be/nl/boekingsbevestiging',
        'fr': 'https://www.duveldome.be/confirmation-de-reservation'
      },
      'logo': {
        'alt':'Logo Duvel Dome',
        'src':'/assets/images/logo-duveldome.svg',
        'href':{
          'en':'https://www.duveldome.be/en/',
          'nl':'https://www.duveldome.be/nl/',
          'fr':'https://www.duveldome.be/'
        }
      },
      'onlineDiscountMessage': {
        'en': 'reduction on activities when paid online',
        'nl': 'korting op activiteiten indien u online betaalt',
        'fr': 'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'
      },
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.fivenationsdurbuy.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-5KHF7R4',
      'title':'Booking | Five Nations Durbuy',
      'favicon':'https://www.fivenationsdurbuy.be/sites/default/themes/custom/wdi_theme/favicon.ico',
      'mode': 'site',
      'ffo': 'FI',
      'formbutton': '',
      'url': 'https://booking.fivenationsdurbuy.be/',
      'urlhash': 'a0c80aa54e03b2f2b63c6ec1741edeadf515c6c40605a74b4fb23d8dc1836f8b',
      'promoCode': '',
      'termsAndConditionsLinks': [{
        'label': {
          'en': 'the terms and conditions',
          'nl': 'de algemene voorwaarden',
          'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href': {
          'en': 'https://www.fivenationsdurbuy.be/en/mentions-legales',
          'nl': 'https://www.fivenationsdurbuy.be/nl/mentions-legales',
          'fr': 'https://www.fivenationsdurbuy.be/fr/mentions-legales'
        }
      }],
      'privacyLink': {
        'label': {'en': 'privacy', 'nl': 'privacy', 'fr': 'vie priv\u00E9e'},
        'href': {
          'en': 'https://www.fivenationsdurbuy.be/en/gdpr',
          'nl': 'https://www.fivenationsdurbuy.be/nl/gdpr',
          'fr': 'https://www.fivenationsdurbuy.be/fr/gdpr'
        }
      },
      'proposeVoucherPayment': true,
      'finishedRedirect': {
        'en': 'https://www.fivenationsdurbuy.be/en/confirmation',
        'nl': 'https://www.fivenationsdurbuy.be/nl/bevestiging',
        'fr': 'https://www.fivenationsdurbuy.be/fr/confirmation'
      },
      'logo': {
        'alt':'Logo Fivenations',
        'src':'/assets/images/logo-fivenations.svg',
        'href':{'en':'https://www.fivenationsdurbuy.be/en','nl':'https://www.fivenationsdurbuy.be/nl','fr':'https://www.fivenationsdurbuy.be/fr'}
      },
      'onlineDiscountMessage': {'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'MULTI':defaultPredateSelectors.MULTI}
    },
    'booking.lelabyrinthe.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-W828V2J',
      'title':'Booking | Le Labyrinthe',
      'favicon':false,
      'mode': 'site',
      'ffo':'LB',
      'formbutton': '',
      'url': 'https://booking.lelabyrinthe.be/',
      'urlhash': '317e5d1d777250b5764c2ec8fcfa55868bd2c89d55006064f1820d9bfe1d1338',
      'promoCode': '',
      'privacyLink':{},
      'proposeVoucherPayment':true,
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://www.lelabyrinthe.be/en/guide-pratique/horaires-tarifs/',
          'nl': 'https://www.lelabyrinthe.be/nl/guide-pratique/horaires-tarifs/',
          'fr': 'https://www.lelabyrinthe.be/guide-pratique/horaires-tarifs/'
        }
      }],
      'finishedRedirect': {
        'en':'https://confirmation.lelabyrinthe.be/en/confirmation.html',
        'nl':'https://confirmation.lelabyrinthe.be/nl/bevestiging.html',
        'fr':'https://confirmation.lelabyrinthe.be/'
      },
      'logo': {
        'alt':'Logo Le Labyrinthe',
        'src':'/assets/images/logo-lelabyrinthe.svg',
        'href':{'en':'https://www.lelabyrinthe.be/en','nl':'https://www.lelabyrinthe.be/nl','fr':'https://www.lelabyrinthe.be'}
      },
      'onlineDiscountMessage':{},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.fivenationsdurbuy.be/giftcard/': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-5KHF7R4',
      'title':'Booking | Five Nations Durbuy',
      'favicon':'https://www.fivenationsdurbuy.be/sites/default/themes/custom/wdi_theme/favicon.ico',
      'mode': 'site',
      'ffo': 'FI',
      'formbutton': '',
      'url': 'https://booking.fivenationsdurbuy.be/giftcard/',
      'urlhash': 'a0c80aa54e03b2f2b63c6ec1741edeadf515c6c40605a74b4fb23d8dc1836f8b',
      'promoCode': '',
      'skipDatesPage':true,
      'termsAndConditionsLinks': [{
        'label': {
          'en': 'the terms and conditions',
          'nl': 'de algemene voorwaarden',
          'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href': {
          'en': 'https://www.fivenationsdurbuy.be/en/mentions-legales',
          'nl': 'https://www.fivenationsdurbuy.be/nl/mentions-legales',
          'fr': 'https://www.fivenationsdurbuy.be/fr/mentions-legales'
        }
      }],
      'privacyLink': {
        'label': {'en': 'privacy', 'nl': 'privacy', 'fr': 'vie priv\u00E9e'},
        'href': {
          'en': 'https://www.fivenationsdurbuy.be/en/gdpr',
          'nl': 'https://www.fivenationsdurbuy.be/nl/gdpr',
          'fr': 'https://www.fivenationsdurbuy.be/fr/gdpr'
        }
      },
      'proposeVoucherPayment': true,
      'finishedRedirect': {
        'en': 'https://www.fivenationsdurbuy.be/en/confirmation',
        'nl': 'https://www.fivenationsdurbuy.be/nl/bevestiging',
        'fr': 'https://www.fivenationsdurbuy.be/fr/confirmation'
      },
      'logo': {
        'alt':'Logo Fivenations',
        'src':'/assets/images/logo-fivenations.svg',
        'href':{'en':'https://www.fivenationsdurbuy.be/en','nl':'https://www.fivenationsdurbuy.be/nl','fr':'https://www.fivenationsdurbuy.be/fr'}
      },
      'onlineDiscountMessage': {'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.saintamour.be/giftcard/': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-WHQWJR3',
      'title':'Booking | Saint-Amour',
      'favicon':'https://saintamour.be/wp-content/uploads/2017/05/cropped-favicon-1-32x32.png',
      'mode': 'site',
      'ffo':'AG',
      'formbutton': '',
      'skipDatesPage':true,
      'url': 'https://booking.saintamour.be/giftcard/',
      'urlhash': '93e1abf5a3d1d39ab0f9de422dc5342b362da39ac72013946aea9786097f14bb',
      'promoCode': '',
      'termsAndConditionsLinks': [{
        'label': {
          'en': 'the terms and conditions',
          'nl': 'de algemene voorwaarden',
          'fr': 'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href': {
          'en': 'https://www.saintamour.be/en/privacy-terms-and-conditions',
          'nl': 'https://www.saintamour.be/nl/privacy-algemene-voorwaarden',
          'fr': 'https://www.saintamour.be/fr/vie-privee-conditions-generales'
        }
      }],
      'privacyLink': {
        'label': {'en': 'privacy', 'nl': 'privacy', 'fr': 'vie priv\u00E9e'},
        'href': {
          'en': 'https://www.saintamour.be/en/privacy-terms-and-conditions',
          'nl': 'https://www.saintamour.be/nl/privacy-algemene-voorwaarden',
          'fr': 'https://www.saintamour.be/fr/vie-privee-conditions-generales'
        }
      },
      'proposeVoucherPayment': true,
      'finishedRedirect': {'en':'https://www.saintamour.be/en','nl':'https://www.saintamour.be/nl','fr':'https://www.saintamour.be/fr'},
      'logo': {
        'alt':'Logo Saint-Amour',
        'src':'/assets/images/logo-saintamour.svg',
        'href':{'en':'https://www.saintamour.be/en','nl':'https://www.saintamour.be/nl','fr':'https://www.saintamour.be/fr'}
      },
      'onlineDiscountMessage': {'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.sanglier-durbuy.be/giftcard/': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-PQ2VT6V',
      'title':'Booking | Sanglier Durbuy',
      'favicon':'https://www.sanglier-durbuy.be/sites/default/themes/custom/wdi_theme/favicon.ico',
      'mode': 'site',
      'ffo':'SG',
      'formbutton': '',
      'skipDatesPage':true,
      'url': 'https://booking.sanglier-durbuy.be/giftcard/',
      'urlhash': '3090676935d85370e0e6bcb16c4dd256528da9b5ee2d684a267422c5da7cc556',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://sanglier-durbuy.be/en/disclaimer',
          'nl': 'https://sanglier-durbuy.be/nl/juridische-vermeldingen',
          'fr': 'https://sanglier-durbuy.be/fr/mentions-legales'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://sanglier-durbuy.be/en/cookies',
          'nl': 'https://sanglier-durbuy.be/nl/cookies',
          'fr': 'https://sanglier-durbuy.be/fr/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://sanglier-durbuy.be/en/gift-voucher-confirmation',
        'nl': 'https://sanglier-durbuy.be/nl/bevestiging-cadeaubon',
        'fr': 'https://sanglier-durbuy.be/fr/confirmation-bon-cadeau'
      },
      'logo': {
        'alt':'Logo Sanglier',
        'src':'/assets/images/logo-sd.svg',
        'href':{
          'en':'https://sanglier-durbuy.be/en',
          'nl':'https://sanglier-durbuy.be/nl',
          'fr':'https://sanglier-durbuy.be/fr'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL}
    },
    'booking.sanglier-durbuy.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr',
      'gtmId':'GTM-PQ2VT6V',
      'title':'Booking | Sanglier Durbuy',
      'favicon':'https://www.sanglier-durbuy.be/sites/default/themes/custom/wdi_theme/favicon.ico',
      'mode': 'site',
      'ffo':'SD',
      'formbutton': '',
      'skipDatesPage':false,
      'url': 'https://booking.sanglier-durbuy.be/',
      'urlhash': '3647f8092ec00ae17cd7fb9a2eb202c6c572dcd32d7e37c68fc16b5cfcc99d6e',
      'promoCode': '',
      'termsAndConditionsLinks':[{
        'label':{
          'en':'the terms and conditions',
          'nl':'de algemene voorwaarden',
          'fr':'les conditions g\u00E9n\u00E9rales et mentions l\u00E9gales'
        },
        'href':{
          'en': 'https://sanglier-durbuy.be/en/disclaimer',
          'nl': 'https://sanglier-durbuy.be/nl/juridische-vermeldingen',
          'fr': 'https://sanglier-durbuy.be/fr/mentions-legales'
        }
      }],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en': 'https://sanglier-durbuy.be/en/cookies',
          'nl': 'https://sanglier-durbuy.be/nl/cookies',
          'fr': 'https://sanglier-durbuy.be/fr/cookies'
        }
      },
      'proposeVoucherPayment':true,
      'finishedRedirect': {
        'en': 'https://sanglier-durbuy.be/en/gift-voucher-confirmation',
        'nl': 'https://sanglier-durbuy.be/nl/bevestiging-cadeaubon',
        'fr': 'https://sanglier-durbuy.be/fr/confirmation-bon-cadeau'
      },
      'logo': {
        'alt':'Logo Sanglier',
        'src':'/assets/images/logo-sd.svg',
        'href':{
          'en':'https://sanglier-durbuy.be/en',
          'nl':'https://sanglier-durbuy.be/nl',
          'fr':'https://sanglier-durbuy.be/fr'
        }
      },
      'onlineDiscountMessage':{'en':'reduction on activities when paid online','nl':'korting op activiteiten indien u online betaalt','fr':'de r\u00E9duction sur des activit\u00E9s si vous payez en ligne'},
      'predateSelectors':{'MULTI':defaultPredateSelectors.MULTI}
    },
    'reservation.takeawaybybru.be': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'defaultLang':'fr'
    },
    'booking.big-c.be': {
      'serviceUrl':'https://bigc.api.outdoorapp.be/services/index.php',
      'imageService':'https://bigc.api.outdoorapp.be/services/templateimage.php',
      'mode':'site',
      'ffo':'BC',
      'defaultLang':'fr',
      'formbutton':'',
      'url':'https://booking.big-c.be/',
      'urlhash':'18c6ba5a4a068eae9a9e1e65d80a0a690848e425cd5e2cd65d3fb2f9e80b36e2',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en':'http://www.big-c.be/',
          'nl':'http://www.big-c.be/',
          'fr':'http://www.big-c.be/'
        }
      },
      'finishedRedirect':{
        'en':'https://www.big-c.be/reservation.php',
        'nl':'https://www.big-c.be/reservation.php',
        'fr':'https://www.big-c.be/reservation.php'
      },
      'proposeVoucherPayment':false,
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'logo': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-logo.svg',
        'href':{
          'en': 'http://www.big-c.be',
          'nl': 'http://www.big-c.be',
          'fr': 'http://www.big-c.be'
        }
      }
    },
    'bigc.outdoorpub.be': {
      'serviceUrl':'https://bigc.api.outdoorapp.be/services/index.php',
      'imageService':'https://bigc.api.outdoorapp.be/services/templateimage.php',
      'url':'https://bigc.outdoorpub.be/',
      'urlhash':'f65529530a2c0ce1826c1f803d6754b5bf48b02195d9728b52eb9e234b9bc9a9',
      'mode':'site',
      'ffo':'BC',
      'defaultLang':'fr',
      'formbutton':'',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':null,
      'finishedRedirect': 'https://bigc.outdoorpub.be/',
      'proposeVoucherPayment':false,
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'skipDatesPage':true,
      'logo': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-logo.svg',
        'href':{
          'en': 'https://bigc.outdoorpub.be/',
          'nl': 'https://bigc.outdoorpub.be/',
          'fr': 'https://bigc.outdoorpub.be/'
        }
      }
    },
    'birthday.big-c.be': {
      'serviceUrl':'https://bigc.api.outdoorapp.be/services/index.php',
      'imageService':'https://bigc.api.outdoorapp.be/services/templateimage.php',
      // 'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'mode':'site',
      'ffo':'BP',
      'defaultLang':'fr',
      'formbutton':'',
      'url':'https://birthday.big-c.be/',
      'urlhash':'f1ac864eaf98807b171ee6f80c82086b312c80e92557b7440d5c66cb945aa740',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en':'http://www.big-c.be/',
          'nl':'http://www.big-c.be/',
          'fr':'http://www.big-c.be/'
        }
      },
      'finishedRedirect':{
        'en':'https://www.big-c.be/reservation.php',
        'nl':'https://www.big-c.be/reservation.php',
        'fr':'https://www.big-c.be/reservation.php'
      },
      'proposeVoucherPayment':false,
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'logo': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-logo.svg',
        'href':{
          'en': 'http://www.big-c.be',
          'nl': 'http://www.big-c.be',
          'fr': 'http://www.big-c.be'
        }
      }
    },
    'bigcbirthday.outdoorpub.be': {
      'serviceUrl':'https://bigc.api.outdoorapp.be/services/index.php',
      'imageService':'https://bigc.api.outdoorapp.be/services/templateimage.php',
      // 'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'mode':'site',
      'ffo':'BP',
      'defaultLang':'fr',
      'formbutton':'',
      'url':'https://bigcbirthday.outdoorpub.be/',
      'urlhash':'4a7028bb1fa2ef07fee19dab46714f111cd37a96257cb6852d777e750a4bb823',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':{
        'label':{'en':'privacy','nl':'privacy','fr':'vie priv\u00E9e'},
        'href':{
          'en':'http://www.big-c.be/',
          'nl':'http://www.big-c.be/',
          'fr':'http://www.big-c.be/'
        }
      },
      'finishedRedirect':{
        'en':'https://www.big-c.be/reservation.php',
        'nl':'https://www.big-c.be/reservation.php',
        'fr':'https://www.big-c.be/reservation.php'
      },
      'proposeVoucherPayment':false,
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'logo': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-logo.svg',
        'href':{
          'en': 'http://www.big-c.be',
          'nl': 'http://www.big-c.be',
          'fr': 'http://www.big-c.be'
        }
      }
    },
    'bigc.kiosk.outdoorpub.be': {
      'serviceUrl':'https://bigc.api.outdoorapp.be/services/index.php',
      'imageService':'https://bigc.api.outdoorapp.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':true,
      'ctepScript':'ctepForKiosk',
      'mode':'kiosk',
      'ffo':'BC',
      'defaultLang':'fr',
      'formbutton':'',
      'url':'http://localhost:3002/',
      'urlhash':'e73b62e92e45f1bdae2c1ca7b543befc4b3bad0fa54f64ca2e54df752359b2a1',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':null,
      'finishedRedirect': null,
      'proposeVoucherPayment':false,
      'predateSelectors':{'SINGL':defaultPredateSelectors.SINGL},
      'logo': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-logo.svg',
        'href':{
          'en': 'http://www.big-c.be',
          'nl': 'http://www.big-c.be',
          'fr': 'http://www.big-c.be'
        }
      },
      'logoLandscape': {
        'alt':'Logo BIGc',
        'src':'/assets/images/bigc-landscape.png',
        'href':{
          'en': 'http://www.big-c.be',
          'nl': 'http://www.big-c.be',
          'fr': 'http://www.big-c.be'
        }
      }
    }
  };
  this.host=null;
  this.langs=['fr','nl','en'];
};
config.prototype.get = function(key) {
  var host=this.getHost();
  return host[key];
};
config.prototype.set = function(key,value) {
  var host=this.getHost();
  host[key]=value;
};
config.prototype.exists = function(key) {
  var host=this.getHost();
  return host.hasOwnProperty(key);
};
config.prototype.getHost = function() {
  if(this.host===null) {
    var hostname=this.$window.location.hostname;
    if(hostname.match(/^www\./)) {
      hostname=hostname.substr(4);
    }
    var pathname=this.$window.location.pathname;
    if(hostname+pathname in this.hosts) {
      hostname+=pathname;
    }
    if(hostname in this.hosts) {
      this.host=this.hosts[hostname];
      var i;
      for(i in this.$window.outdoorArgs) {
        this.host[i]=this.$window.outdoorArgs[i];
      }
    } else {
      this.$log.log('ERROR: domain <'+hostname+'> is not configured');
    }
  }
  return this.host;
};
angular.module('app').service('config',['$window','$log',config]);
